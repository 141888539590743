import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, withStyles, Button, CardMedia, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import SelectBox from './SelectBox'
import {
  listDefault,
  languageList,
  selectData,
  AUTHEN_TOKEN,
  languageListKey,
  titleLanguage,
  contentLanguage,
  languageName,
  languageKey,
} from '../../constants/define'
import { resizeImage } from '../../commons'
import { pushNotificationApi } from '../../api'
import LanguageInput from '../../components/LanguageInput'

const maxTitle = 200
const maxContent = 1024

// màn hình bắn notification
/**
 * bao gồm các lựa chọn đối tượng nhận được notification
 * cho phép nhập title, chọn ảnh, nhập content
 */
class PushNotification extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()

    this.file = undefined
    this.genderSelect = React.createRef()
    this.ageSelect = React.createRef()
    this.nationalitySelect = React.createRef()
    this.numberVisitSelect = React.createRef()
    this.header = React.createRef()
    this.languageTitle = React.createRef()
    this.languageContent = React.createRef()
  }

  getInitialState = state => ({
    titleDefalut: '',
    titleVi: '',
    titleEn: '',
    titleJa: '',
    titleKo: '',
    errorTitle: '',
    titleTranslate: languageList.map(item => ({ ...item, value: '' })),
    isLoading: false,
    image: '',

    contentDefalt: '',
    contentVi: '',
    contentEn: '',
    contentJa: '',
    contentKo: '',
    errorContent: '',
    error: '',
    contentTranslate: languageList.map(item => ({ ...item, value: '' })),
    message: '',
    imageUpload: undefined,
    currentLanguageTitle: 'vi',
    currentLanguageContent: 'vi',
    ...state,
  })

  getLanguage = () => {
    const { language } = this.props
    return language
  }

  /**
   * Reset State function
   */
  resetState = state => {
    this.setState(this.getInitialState(state))
    if (this.languageContent.current) {
      this.languageContent.current.onResetState()
    }
    if (this.languageTitle.current) {
      this.languageTitle.current.onResetState()
    }
  }

  // func thay đổi title
  // check xem phần title nào được thay đổi sẽ setState cho mục đó
  // slice maxTitle
  onChangeTitle = ({ event }) => {
    const { value, name } = event.target

    this.setState({ [name]: value.slice(0, maxTitle), errorTitle: '' })
  }

  // func thay đổi content
  // check xem phần content nào được thay đổi sẽ setState cho mục đó
  // slice maxcontent
  onChangeContent = ({ event }) => {
    const { value, name } = event.target

    this.setState({ [name]: value.slice(0, maxContent), errorContent: '' })
  }

  // chọn ảnh cho notification
  // không bắt buộc
  onChooseImage = event => {
    const { files } = event.nativeEvent.target
    const file = files[0]
    if (file) {
      resizeImage(file)
        .then(result => {
          const { errorImage, image, imageUpload } = result
          if (!errorImage) {
            this.setState({
              image,
              imageUpload,
            })
            // this.fileAvatar = file
          }
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  /**
   * Lấy data của các checkbox
   */
  onGetSelectData = type => {
    switch (type) {
      case selectData.gender:
        if (this.genderSelect.current) {
          return this.genderSelect.current.getData()
        }
        return []

      case selectData.age:
        if (this.ageSelect.current) {
          return this.ageSelect.current.getData()
        }
        return []

      case selectData.nationality:
        if (this.nationalitySelect.current) {
          return this.nationalitySelect.current.getData()
        }
        return []

      case selectData.numberVisit:
        if (this.numberVisitSelect.current) {
          return this.numberVisitSelect.current.getData()
        }
        return []

      default:
        return []
    }
  }

  /**
   * Convert data các checkbox
   */
  convertSelectData = type => {
    const currentData = this.onGetSelectData(type)
    const convertData = currentData[0].check
      ? currentData.filter(value => value.name !== 'all')
      : _.filter(currentData, value => value.check && value.name !== 'all')
    return convertData
  }

  renderSelect = () => {
    const { language } = this.props
    return (
      <div>
        <SelectBox
          language={language}
          ref={this.genderSelect}
          title={language.t('member.gender')}
          defaultData={listDefault.gender}
        />
        <SelectBox
          language={language}
          ref={this.ageSelect}
          title={language.t('member.age')}
          defaultData={listDefault.age}
        />
        {/* <SelectBox
          language={language}
          ref={this.nationalitySelect}
          title={language.t('nationality')}
          defaultData={listDefault.nationality}
        />
        <SelectBox
          language={language}
          ref={this.numberVisitSelect}
          title={language.t('dashboard.number_of_visit')}
          defaultData={listDefault.numbervisit}
        /> */}
      </div>
    )
  }

  renderErrorField = error => {
    const { classes } = this.props
    if (!error) return null
    return (
      <Grid item xs={12} sm={12} md={10}>
        <div>
          <p className={classes.textError}>{this.getLanguage().t(error)}</p>
        </div>
      </Grid>
    )
  }

  renderLength = ({ current, max, error = false }) => {
    const { classes } = this.props
    return (
      <Grid item xs={12} sm={12} md={12}>
        <p className={`${error && classes.textError} ${classes.textLength}`}>
          {current}/{max}
        </p>
      </Grid>
    )
  }

  /**
   * Render multi language title
   */
  renderMultilanguageTitle = () => {
    return (
      <div>
        <LanguageInput ref={this.languageTitle} onChangeLanguage={this.onChangeLanguageTitle} />
      </div>
    )
  }

  /**
   * On change language name
   */
  onChangeLanguageTitle = locale => {
    this.setState({
      currentLanguageTitle: locale,
    })
  }

  renderValueTitle = () => {
    const { currentLanguageTitle, titleVi, titleEn, titleJa, titleKo } = this.state
    if (currentLanguageTitle === languageKey.vi) {
      return titleVi
    }
    if (currentLanguageTitle === languageKey.en) {
      return titleEn
    }
    if (currentLanguageTitle === languageKey.ko) {
      return titleKo
    }
    return titleJa
  }

  renderTitle = () => {
    const { titleDefalut, errorTitle, currentLanguageTitle } = this.state
    const { language } = this.props
    const titleMultiValue = this.renderValueTitle()
    return (
      <>
        <Grid container style={{ marginTop: '60px' }}>
          <Grid item xs sm={2} md={2}>
            <span>{`${language.t('title')} (*)`}</span>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <TextField
              value={titleDefalut}
              variant="outlined"
              size="small"
              name="titleDefalut"
              style={{ width: '100%' }}
              onChange={event => this.onChangeTitle({ event })}
            />
            <Grid container>
              {this.renderErrorField(errorTitle)}
              {this.renderLength({ current: titleDefalut.length, max: maxTitle, error: true })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs sm={2} md={2}>
            <span>{`${language.t('title')} (${language.t(
              languageName[currentLanguageTitle]
            )})`}</span>
          </Grid>
          <Grid item xs={11} sm={9} md={5}>
            <TextField
              value={titleMultiValue}
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              name={titleLanguage[currentLanguageTitle]}
              onChange={event => this.onChangeTitle({ event })}
            />
            <Grid container>
              {this.renderLength({ current: titleMultiValue.length, max: maxTitle, error: true })}
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: '10px' }}>
            {this.renderMultilanguageTitle()}
          </Grid>
        </Grid>
        {/* {titleTranslate.map((item, index) => {
          return (
            <Grid key={item.id} container style={{ marginTop: 15 }}>
              <Grid item xs sm={2} md={2}>
                <span>Title ({item.label})</span>
              </Grid>
              <Grid item xs={12} sm={10} md={6}>
                <TextField
                  value={item.value}
                  variant="outlined"
                  size="small"
                  style={{ width: '100%' }}
                  onChange={event => this.onChangeTitle({ event, index })}
                />
                {this.renderLength({ current: item.value.length, max: maxTitle, error: false })}
              </Grid>
            </Grid>
          )
        })} */}
      </>
    )
  }

  renderImage = () => {
    const { classes, language } = this.props
    const { image } = this.state
    const textUpload = image ? 'edit' : 'addNewImage'
    return (
      <Grid container>
        <Grid item xs sm={2} md={2}>
          <span>{language.t('image')}</span>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          {image && (
            <div>
              <CardMedia className={classes.media} image={image} title="Ảnh minh hoạ" />
            </div>
          )}
          <Button
            variant="contained"
            component="label"
            color="primary"
            className={`${classes.buttonSend} ${classes.buttonUpload}`}
          >
            {language.t(textUpload)}
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={this.onChooseImage}
              accept="image/x-png,image/jpeg"
            />
          </Button>
        </Grid>
      </Grid>
    )
  }

  /**
   * Render multi language Content
   */
  renderMultilanguageContent = () => {
    return (
      <div>
        <LanguageInput ref={this.languageContent} onChangeLanguage={this.onChangeLanguageContent} />
      </div>
    )
  }

  /**
   * On change language Content
   */
  onChangeLanguageContent = locale => {
    this.setState({
      currentLanguageContent: locale,
    })
  }

  /**
   * Render value content
   */
  renderValueContent = () => {
    const { currentLanguageContent, contentVi, contentEn, contentJa, contentKo } = this.state
    if (currentLanguageContent === languageKey.vi) {
      return contentVi
    }
    if (currentLanguageContent === languageKey.en) {
      return contentEn
    }
    if (currentLanguageContent === languageKey.ko) {
      return contentKo
    }
    return contentJa
  }

  renderContent = () => {
    const { language } = this.props
    const { contentDefalt, errorContent, currentLanguageContent } = this.state
    const contentMultivalue = this.renderValueContent()
    return (
      <>
        <Grid container style={{ marginTop: 15 }}>
          <Grid item xs sm={2} md={2}>
            <span>{`${language.t('content')}(*)`}</span>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <TextField
              value={contentDefalt}
              multiline
              name="contentDefalt"
              rows={4}
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              onChange={event => this.onChangeContent({ event })}
            />
            <Grid container>
              {this.renderErrorField(errorContent)}
              {this.renderLength({ current: contentDefalt.length, max: maxContent, error: true })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs sm={2} md={2}>
            <span>{`${language.t('content')} (${language.t(
              languageName[currentLanguageContent]
            )})`}</span>
          </Grid>
          <Grid item xs={11} sm={9} md={5}>
            <TextField
              value={contentMultivalue}
              variant="outlined"
              size="small"
              multiline
              rows={4}
              style={{ width: '100%' }}
              name={contentLanguage[currentLanguageContent]}
              onChange={event => this.onChangeContent({ event })}
            />
            <Grid container>
              {this.renderLength({
                current: contentMultivalue.length,
                max: maxContent,
                error: true,
              })}
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: '10px' }}>
            {this.renderMultilanguageContent()}
          </Grid>
        </Grid>
        {/* {contentTranslate.map(item => {
          return (
            <Grid key={item.id} container style={{ marginTop: 15 }}>
              <Grid item xs sm={2} md={2}>
                <span>Content ({item.label})</span>
              </Grid>
              <Grid item xs={12} sm={10} md={6}>
                <TextField
                  value={item.value}
                  multiline
                  rows={4}
                  variant="outlined"
                  size="small"
                  style={{ width: '100%' }}
                />
                {this.renderLength({ current: item.value.length, max: maxContent, error: false })}
              </Grid>
            </Grid>
          )
        })} */}
      </>
    )
  }

  renderInput = () => {
    return (
      <>
        {this.renderTitle()}
        {this.renderImage()}
        {this.renderContent()}
      </>
    )
  }

  /**
   * handle Submit
   */
  onSubmit = async () => {
    const dataGender = this.convertSelectData(selectData.gender)
    const dataAge = this.convertSelectData(selectData.age)
    // const dataNationality = this.convertSelectData(selectData.nationality)
    // const dataNumberVisit = this.convertSelectData(selectData.numberVisit)
    const {
      titleDefalut,
      errorTitle,
      contentDefalt,
      errorContent,
      imageUpload,
      titleVi,
      titleEn,
      titleJa,
      titleKo,
      contentVi,
      contentEn,
      contentKo,
      contentJa,
    } = this.state

    let check = true
    const error = {}
    if (!titleDefalut || titleDefalut.length <= 0) {
      error.errorTitle = 'errorNotification.requiredTitle'
      check = false
    }
    if (!contentDefalt || contentDefalt.length <= 0) {
      error.errorContent = 'errorNotification.requiredContent'
      check = false
    }

    if (errorTitle || errorContent) {
      check = false
      return
    }

    if (!check) {
      this.setState({
        ...error,
      })
    } else {
      try {
        const translations = [
          {
            id: languageListKey.vi,
            idLanguage: languageListKey.vi,
            title: titleVi,
            content: contentVi,
          },
          {
            id: languageListKey.ja,
            idLanguage: languageListKey.ja,
            title: titleJa,
            content: contentJa,
          },
          {
            id: languageListKey.ko,
            idLanguage: languageListKey.ko,
            title: titleKo,
            content: contentKo,
          },
          {
            id: languageListKey.en,
            idLanguage: languageListKey.en,
            title: titleEn,
            content: contentEn,
          },
        ]

        const params = {
          title: titleDefalut,
          content: contentDefalt,
          genders: dataGender,
          age_ranges: dataAge,
          translations,
          // nationalities: dataNationality,
          // number_of_visit_ranges: dataNumberVisit,
        }
        if (imageUpload) {
          params.image = imageUpload
        }

        this.setState({
          isLoading: true,
        })
        const data = await pushNotificationApi({
          authen: localStorage.getItem(AUTHEN_TOKEN),
          ...params,
        })

        if (data.status === 'success') {
          this.resetState({ message: 'pushSuccess' })
        } else {
          this.setState({
            isLoading: false,
            error: 'somethingWrong',
          })
        }
        this.header.current.scrollIntoView()
        setTimeout(() => {
          this.setState({ message: '', error: '' })
        }, 5000)
      } catch (e) {
        console.warn(e)
      }
    }
  }

  renderSubmit = () => {
    const { isLoading } = this.state
    const { language } = this.props
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: '#F7941D', width: 80, alignItems: 'center', marginTop: '50px' }}
        onClick={this.onSubmit}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" style={{ color: '#fff' }} />
        ) : (
          language.t('save')
        )}
      </Button>
    )
  }

  renderAlert = () => {
    const { error, message } = this.state
    const { language } = this.props
    return (
      <>
        {(message || error) && (
          <Alert variant="outlined" severity={message ? 'success' : 'error'}>
            {language.t(message || error)}
          </Alert>
        )}
      </>
    )
  }

  render() {
    return (
      <div ref={this.header}>
        {this.renderAlert()}
        {this.renderSelect()}
        {this.renderInput()}
        {this.renderSubmit()}
      </div>
    )
  }
}

PushNotification.propTypes = {
  classes: PropTypes.any,
  language: PropTypes.any,
}

const styles = {
  textError: {
    color: 'red',
    margin: 0,
  },
  textLength: {
    textAlign: 'end',
    margin: 0,
  },
  buttonSend: {
    backgroundColor: '#f89a2b',
  },
  media: {
    width: 160,
    height: 160,
    backgroundSize: 'contain',
    marginBottom: 20,
    marginRight: 20,
    // paddingTop: '56.25%', // 16:9
  },
}

const mapState = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapState)(withStyles(styles)(PushNotification))
